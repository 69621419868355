@use 'mixins' as *;

.generic-plug {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  aspect-ratio: 1 / 1;

  a {
    inline-size: 100%;

    &:hover {
      img {
        opacity: 0.95;
      }
    }
  }

  &__background-black {
    background: var(--color-black);
  }
}

.content-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  block-size: 100%;

  &__align-left {
    justify-content: flex-start;
  }

  &__align-right {
    justify-content: flex-end;
  }
}

.plug-big-title {
  font-size: 17vw;
  line-height: 97%;
  font-family: var(--font-league-gothic) !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  inline-size: 70%;
  margin-inline: auto;
  letter-spacing: 2px;
  white-space: pre-wrap;
  display: inline-block;
  word-spacing: 100vw;
  filter: drop-shadow(1px 0 3px rgba(0, 0, 0, 0.22));

  @include breakpoint-min(small) {
    font-size: 9vw;
  }

  @include breakpoint-min(medium) {
    font-size: 4vw;
  }

  &__align-left {
    text-align: left;
  }

  &__align-right {
    text-align: right;
  }

  &__col-2 {
    @include breakpoint-min(small) {
      font-size: 7vw;
    }
  }
}

.less-than-three {
  font-size: 18vw;

  @include breakpoint-min(small) {
    font-size: 11vw;
  }

  @include breakpoint-min(medium) {
    font-size: 5vw;
  }

  &__col-2 {
    @include breakpoint-min(small) {
      font-size: 9vw;
    }
  }
}

.cta-box {
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  max-inline-size: 305px;
  text-align: center;
  margin-block-end: var(--spacing-regular);
  background-color: var(--color-gray5);
  --size-factor: 100vw;
  font-size: clamp(1rem, calc(1rem + var(--size-factor)), 100rem);

  @include breakpoint-min(tiny) {
    --size-factor: 50vw;
  }

  @include breakpoint-min(medium) {
    max-inline-size: 25rem;
  }

  @include breakpoint-min(max) {
    max-inline-size: 30rem;
  }
}

.cta-text {
  padding-block: 1.6em;
  appearance: none;
  max-width: 350px;
  min-width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: var(--font-size-header-3-content);
  line-height: var(--line-heitht-header-3-content);
  font-weight: var(----font-weight-medium);
  background-color: var(--color-gray5);
  color: var(--color-black);

  @include breakpoint-min(medium) {
    padding-block: 2em;
  }
}
